import { DNBButton, DNBCheckbox } from '@dnb-uux-design-system/react'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toSentenceCase } from '../../helpers'
import { Accordion, AccordionHeader, AccordionPanel, Divider, Modal } from '../../local-core-ui'
import { Pattern } from '../../types'
import { DataBlockElementSelector } from '../data-block-element-selector/data-block-element-selector'
import arrayPrefixes from '../data-block-searcher/array-prefixes.json'
import mandatoryElements from '../data-block-searcher/arrayMandatoryElesAndAlerts.json'
import disabledArrayElements from '../data-block-searcher/disabledArrayElements.json'
import { ElementTree } from '../data-block-searcher/element-tree'
import { ElementUIFacade } from '../data-block-searcher/ElementUIFacade'
import {
	allFilteredElementsAreSelected,
	areAllElementsInTreeSelected,
	evaluateChildrenToDeleteSelected,
	evaluateChildsToAddToSelected,
	generateAdditionalTextString,
	getAllElementsToSelect,
	getElementsBelongingToRequiredGroup,
	isElementChecked,
	isElementDisabled,
	isElementIdSubstringExists,
	OutputElementsSelection
} from '../data-block-searcher/helpers/element-tree-helpers'
import mandatoryAgingElements from '../data-block-searcher/rangesandfigures-prefixes.json'
import styles from './datablock-results.module.scss'

interface DataSearcherProps {
	flattenSelectedElements: Array<ElementUIFacade>
	flattenDefaultElements: Array<ElementUIFacade>
	displayedItems: number
	filteredElementList: Array<ElementUIFacade>

	toggleElement(newSelectedElements: Array<ElementUIFacade>, changedElements: boolean): void

	fetchMoreData(levelElements: ElementUIFacade[], itemsDisplayed: number): void

	testId: string
	showBlockInformation?: boolean
	isErrorBannerVisible: boolean

	errorBannerVisibility(mandatoryIdArray: Array<string>): void

	onChangeFilter: (value: string) => void
	activeTradeUp?: boolean

	setShowSearchSection(value: boolean): void

	setSearchInputValue(value: string): void

	enableEmailVerification?: boolean
	EVIDS?: Array<string>
	tradeToggle?: boolean
	isRecommendationMatch?: boolean
}

export const DataBlockResults = ({
	flattenSelectedElements,
	flattenDefaultElements,
	displayedItems,
	filteredElementList,
	toggleElement,
	fetchMoreData,
	testId,
	showBlockInformation = true,
	isErrorBannerVisible,
	errorBannerVisibility,
	onChangeFilter,
	activeTradeUp = false,
	setShowSearchSection,
	setSearchInputValue,
	enableEmailVerification,
	EVIDS,
	tradeToggle,
	isRecommendationMatch
}: DataSearcherProps): ReactElement => {
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState<boolean>(true)
	const [elementsToDisplay, setElementsToDisplay] = useState(filteredElementList)
	const [mandatoryIdArray, setMandatoryIdArray] = useState<Array<string>>([])
	const [showSelectAllElementsModal, setShowSelectAllElementsModal] = useState(false)
	const [selectAllChecked, setSelectAllChecked] = useState(false)
	const [elementsHasChildrenWithDropdown, setElementsHasChildrenWithDropdown] = useState(false)

	useEffect(() => {
		if (elementsToDisplay.length > 0) {
			for (const element of elementsToDisplay) {
				if (!!(element.childs && element.outputDropdownOptions && element.outputDropdownOptions.length > 0)) {
					setElementsHasChildrenWithDropdown(true)
					break
				} else {
					setElementsHasChildrenWithDropdown(false)
				}
			}
		}
	}, [elementsToDisplay])

	useEffect(() => {
		setSelectAllChecked(
			allFilteredElementsAreSelected(
				filteredElementList,
				flattenSelectedElements,
				enableEmailVerification,
				EVIDS,
				tradeToggle
			)
		)
		// This useEffect needs to be triggered only when the selected elements or the filtered elements change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flattenSelectedElements, filteredElementList])

	useEffect(() => {
		const defaultRecommendationEleToFilter = [
			'matchqualityinfo_matchInsight_summarycode',
			'matchqualityinfo_matchInsight_summarydesc',
			'matchqualityinfo_matchInsight_stewardshipvalue',
			'matchqualityinfo_matchInsight_reqElementUsed'
		]
		isRecommendationMatch
			? setElementsToDisplay(filteredElementList)
			: setElementsToDisplay(
					filteredElementList.filter((ele) => {
						return defaultRecommendationEleToFilter.indexOf(ele.elementId) === -1
					})
			  )
		// Why the F... did I put this? well the parent compo (all-data-block-searcher) is responsible for defining the
		// filteredElementList which is received here as a prop. A child compo does not rerender on prop changes but on
		// state changes, so we are transferring the filteredElementList to elementsToDisplay and are doing so whenever
		// we get a new filteredElementList, but wait, how can we know there is a new filteredElementList? well an array
		// being an object does not change when elements are added / deleted so it would stay static even if the elements
		// change, so we stringify it using a reducer, if the string changes, then we know it has changed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		filteredElementList.reduce(
			(prev: string, element): string => `${prev}:${element?.blockId}-${element.elementId}`,
			''
		)
	])

	const selectAllElements = ({
		checked,
		filteredElements,
		enableEmailVerification,
		EVIDS,
		outputElementsSelection
	}: {
		checked: boolean
		filteredElements: Array<ElementUIFacade>
		enableEmailVerification?: boolean
		EVIDS?: Array<string>
		outputElementsSelection?: OutputElementsSelection
	}) => {
		const filterIDS = filteredElements?.map(function (element: ElementUIFacade) {
			return element.elementId
		})
		const checkDifferences = filterIDS?.every((element) => EVIDS?.includes(element))
		if (checkDifferences && enableEmailVerification === false) return false
		else {
			toggleElement(
				getAllElementsToSelect(
					checked,
					filteredElements,
					flattenSelectedElements,
					flattenDefaultElements,
					activeTradeUp,
					enableEmailVerification,
					EVIDS,
					outputElementsSelection
				),
				true
			)
		}
		setMandatoryIdArray([])
		errorBannerVisibility([])
	}

	const isElementSelectedInArray = (element: ElementUIFacade) => {
		const prefixes: Array<string> = mandatoryElements[element?.elementId as keyof typeof mandatoryElements]
		const prefixesAging: Array<string> =
			mandatoryAgingElements[element?.elementId as keyof typeof mandatoryAgingElements]
		let isAnyRequiredElementSelected
		// TODO remove prefixes and prefixesAging after all checkbox patterns have been moved to new implementation
		if (prefixes && prefixesAging) {
			isAnyRequiredElementSelected = flattenSelectedElements.some((element) =>
				isElementIdSubstringExists(prefixes, element.elementId)
			)
			if (element?.childs && element?.checkboxGroupOptions) {
				const totalLength = element?.childs?.length * element?.checkboxGroupOptions?.length
				const selectedArray: string[] = []
				element?.childs?.forEach((child) => {
					child?.childs?.forEach((grandChild) => {
						flattenSelectedElements?.some((selectedElement) => {
							if (selectedElement?.elementId === grandChild?.elementId)
								selectedArray.push(selectedElement.elementId)
						})
					})
				})
				return isAnyRequiredElementSelected
					? selectedArray?.length > 0 && selectedArray?.length < totalLength
					: false
			}
		} else if (element.pattern === Pattern.CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN) {
			const totalNumberOfDisplayedElements = element.childs?.length || 0
			const selectedDisplayedElements =
				element.childs?.filter((displayedElement) =>
					displayedElement.childs?.some((elementToReview) =>
						flattenSelectedElements.some(
							(selectedElement) => selectedElement.elementId === elementToReview.elementId
						)
					)
				).length || 0
			return selectedDisplayedElements > 0 && selectedDisplayedElements < totalNumberOfDisplayedElements
		} else if (element.isNestedSelector && areAllElementsInTreeSelected(flattenSelectedElements, element)) {
			return false
		}
		// New Implementation of flattened arrays configuration
		else if (element.pattern === Pattern.CHECKBOX_GROUP_PATTERN) {
			const elementsInRequiredGroup = getElementsBelongingToRequiredGroup(element)

			isAnyRequiredElementSelected =
				elementsInRequiredGroup.length > 0
					? flattenSelectedElements.some((selectedElement) =>
							elementsInRequiredGroup.includes(selectedElement.elementId)
					  )
					: true
			if (element?.childs && element?.checkboxGroupOptions) {
				const totalLength = element?.childs?.length * element?.checkboxGroupOptions?.length
				const selectedArray: string[] = []
				element?.childs?.forEach((child) => {
					child?.childs?.forEach((grandChild) => {
						flattenSelectedElements?.some((selectedElement) => {
							if (selectedElement?.elementId === grandChild?.elementId) {
								selectedArray.push(selectedElement.elementId)
							}
						})
					})
				})
				return isAnyRequiredElementSelected
					? selectedArray?.length > 0 && selectedArray?.length < totalLength
					: false
			}
		}
		// New Implementation of flattened arrays configuration
		else {
			if (prefixes) {
				isAnyRequiredElementSelected = flattenSelectedElements.some(
					(element) =>
						prefixes.indexOf(element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_') >= 0
				)
			} else {
				// New Implementation of flattened arrays configuration
				const elementsInRequiredGroup = getElementsBelongingToRequiredGroup(element)
				if (
					elementsInRequiredGroup.length !== 0 &&
					!flattenSelectedElements.some((selectedElement) =>
						elementsInRequiredGroup.includes(selectedElement.elementId)
					)
				) {
					return false
				}
				// New Implementation of flattened arrays configuration
			}
			if (element?.childs) {
				const selectedEleCount = element.childs.reduce((selectedEleCount: number, child) => {
					if (child?.childs && child?.childs.length > 0) {
						const arrayElementId = child?.childs[0].elementId
						const filteredList = flattenSelectedElements.filter((selectedElement) => {
							return selectedElement.elementId === arrayElementId
						})
						filteredList && filteredList.length > 0 && selectedEleCount++
					}
					return selectedEleCount
				}, 0)
				return !prefixes || (prefixes && isAnyRequiredElementSelected)
					? selectedEleCount > 0 && selectedEleCount < element?.childs.length
					: false
			}
		}
	}

	const onChangeElement = (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => {
		let newSelectedElements: Array<ElementUIFacade> = [...flattenSelectedElements]
		if (e.target.checked) {
			if (option.childs) {
				evaluateChildsToAddToSelected({ newSelectedElements, option })
			} else {
				newSelectedElements.push(option)
			}
		} else {
			const foundSelectedElementIndex = newSelectedElements.findIndex(
				(currentSelectedElement) => currentSelectedElement.elementId === option.elementId
			)
			if (foundSelectedElementIndex !== -1) {
				newSelectedElements.splice(foundSelectedElementIndex, 1)
			} else {
				newSelectedElements = evaluateChildrenToDeleteSelected(
					newSelectedElements,
					option,
					flattenDefaultElements
				)
			}
			if (option.pattern === Pattern.CHECKBOX_GROUP_PATTERN) {
				option.checkboxGroupOptions = option.checkboxGroupOptions?.map((checkboxOption) => {
					return { ...checkboxOption, isChecked: false }
				})
			}
		}
		toggleElement(newSelectedElements, true)
		if (option?.parent?.elementId) {
			// New Implementation of flattened arrays configuration
			const elementsInRequiredGroup = getElementsBelongingToRequiredGroup(option.parent)
			const isCheckboxOptionSelected = option.parent.checkboxGroupOptions?.some(
				(checkboxOption) => checkboxOption.isChecked
			)
			if (
				elementsInRequiredGroup.length !== 0 &&
				(option.parent.pattern === Pattern.SINGLE_DROPDOWN_PATTERN ||
					(option.parent.pattern === Pattern.CHECKBOX_GROUP_PATTERN && isCheckboxOptionSelected))
			) {
				if (
					newSelectedElements.some((selectedElement) => {
						return elementsInRequiredGroup.includes(selectedElement.elementId)
					}) &&
					mandatoryIdArray.includes(option.parent.displayName)
				) {
					mandatoryIdArray.splice(mandatoryIdArray.indexOf(option.parent.displayName), 1)
					setMandatoryIdArray(mandatoryIdArray)
					errorBannerVisibility(mandatoryIdArray)
				} else if (
					!newSelectedElements.some((selectedElement) => {
						return elementsInRequiredGroup.includes(selectedElement.elementId)
					}) &&
					!mandatoryIdArray.includes(option.parent.displayName)
				) {
					const newMandatoryIdArray = [...mandatoryIdArray, option.parent.displayName]
					setMandatoryIdArray(newMandatoryIdArray)
					errorBannerVisibility(newMandatoryIdArray)
				}
			}
			// New Implementation of flattened arrays configuration

			const prefixesAging: Array<string> =
				mandatoryAgingElements[option?.parent?.elementId as keyof typeof mandatoryAgingElements]
			const prefixes: Array<string> =
				mandatoryElements[option.parent?.elementId as keyof typeof mandatoryElements]
			const totalPrefixes: Array<string> = arrayPrefixes[option.parent?.elementId as keyof typeof arrayPrefixes]
			const checkPrefixDifferences = totalPrefixes?.every((element) => prefixes?.includes(element))
			if (option?.parent?.elementId && !checkPrefixDifferences && prefixes) {
				let mandatoryError
				let checkElementAdded
				if (prefixesAging && prefixes && totalPrefixes) {
					mandatoryError = newSelectedElements.some((element) =>
						isElementIdSubstringExists(prefixes, element.elementId)
					)
					checkElementAdded = newSelectedElements.some((element) =>
						isElementIdSubstringExists(totalPrefixes, element.elementId)
					)
				} else {
					mandatoryError = newSelectedElements.some(
						(element) =>
							prefixes?.indexOf(
								element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_'
							) >= 0
					)
					checkElementAdded = newSelectedElements.some(
						(element) =>
							totalPrefixes?.indexOf(
								element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_'
							) >= 0
					)
				}
				if (
					option?.parent?.elementId &&
					!mandatoryError &&
					mandatoryIdArray.indexOf(option.parent.displayName) < 0
				) {
					if (checkElementAdded) {
						setMandatoryIdArray([...mandatoryIdArray, option.parent.displayName])
						errorBannerVisibility([...mandatoryIdArray, option.parent.displayName])
					} else {
						//do nothing
					}
				} else {
					if (
						option?.parent?.elementId &&
						mandatoryError &&
						mandatoryIdArray.indexOf(option.parent.displayName) >= 0
					) {
						mandatoryIdArray.splice(mandatoryIdArray.indexOf(option.parent.displayName), 1)
						setMandatoryIdArray(mandatoryIdArray)
						errorBannerVisibility(mandatoryIdArray)
					} else {
						if (
							option?.parent?.elementId &&
							!mandatoryError &&
							mandatoryIdArray.indexOf(option.parent.displayName) >= 0 &&
							!checkElementAdded
						) {
							mandatoryIdArray.splice(mandatoryIdArray.indexOf(option.parent.displayName), 1)
							setMandatoryIdArray(mandatoryIdArray)
							errorBannerVisibility(mandatoryIdArray)
						}
					}
				}
			} else {
				if (checkPrefixDifferences && prefixes) {
					let mandatoryError
					if (prefixesAging && prefixes) {
						mandatoryError = newSelectedElements.some((element) =>
							isElementIdSubstringExists(prefixes, element.elementId)
						)
					} else {
						mandatoryError = newSelectedElements.some(
							(element) =>
								prefixes?.indexOf(
									element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_'
								) >= 0
						)
					}
					if (mandatoryError && mandatoryIdArray.indexOf(option.parent.displayName) >= 0) {
						const updatedMandatoryArray = mandatoryIdArray.filter((ele) => {
							return ele !== option?.parent?.displayName
						})
						setMandatoryIdArray(updatedMandatoryArray)
						errorBannerVisibility(updatedMandatoryArray)
					}
				}
			}
		} else {
			if (option?.elementId) {
				const prefixes: Array<string> = mandatoryElements[option?.elementId as keyof typeof mandatoryElements]
				const prefixesAging: Array<string> =
					mandatoryAgingElements[option?.elementId as keyof typeof mandatoryAgingElements]
				let mandatoryError
				if (prefixesAging && prefixes) {
					mandatoryError = newSelectedElements.some((element) =>
						isElementIdSubstringExists(prefixes, element.elementId)
					)
				} else {
					mandatoryError = newSelectedElements.some(
						(element) =>
							prefixes?.indexOf(
								element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_'
							) >= 0
					)
				}
				if (prefixes && mandatoryError && mandatoryIdArray.indexOf(option.displayName) >= 0) {
					mandatoryIdArray.splice(mandatoryIdArray.indexOf(option.displayName), 1)
					setMandatoryIdArray(mandatoryIdArray)
					errorBannerVisibility(mandatoryIdArray)
				}
				// New Implementation of flattened arrays configuration
				if (
					option.childs?.some((child) => child.belongsToRequiredGroup) &&
					mandatoryIdArray.includes(option.displayName)
				) {
					mandatoryIdArray.splice(mandatoryIdArray.indexOf(option.displayName), 1)
					setMandatoryIdArray(mandatoryIdArray)
					errorBannerVisibility(mandatoryIdArray)
				}
				// New Implementation of flattened arrays configuration
			}
		}
	}

	const resolve = () => {
		if (mandatoryIdArray[0]) {
			setShowSearchSection(true)
			setSearchInputValue(mandatoryIdArray[0])
			onChangeFilter(mandatoryIdArray[0])
		}
	}

	const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked

		if (checked && elementsHasChildrenWithDropdown) {
			setShowSelectAllElementsModal(true)
			return
		}

		selectAllElements({
			checked,
			filteredElements: filteredElementList,
			enableEmailVerification,
			EVIDS
		})
	}

	const handleSetMaxValuesClick = () => {
		setShowSelectAllElementsModal(false)
		selectAllElements({
			checked: true,
			filteredElements: filteredElementList,
			enableEmailVerification,
			EVIDS,
			outputElementsSelection: 'max'
		})
	}

	const handleKeepDefaultValuesClick = () => {
		setShowSelectAllElementsModal(false)
		selectAllElements({
			checked: true,
			filteredElements: filteredElementList,
			enableEmailVerification,
			EVIDS,
			outputElementsSelection: 'default'
		})
	}

	return (
		<div className={styles.datablockResultsContainer}>
			<Modal
				open={showSelectAllElementsModal}
				showButtonClose
				onClose={() => {
					setShowSelectAllElementsModal(false)
				}}
				testId={`${testId}-select-all-elements-modal`}
			>
				<div className={styles.selectAllElementsModalContent}>
					<div className={styles.textWrapper}>
						<p className={styles.title}>{t('datablock.results.modal.selectAllElements.title')}</p>

						<p className={styles.description}>
							{t('datablock.results.modal.selectAllElements.description')}
						</p>

						<p className={styles.description}>
							<Trans i18nKey={'datablock.results.modal.selectAllElements.warning'} />
						</p>
					</div>
					<div className={styles.confirmationButtonsContainer}>
						<DNBButton
							size="default"
							variant="secondary"
							onClick={handleSetMaxValuesClick}
							data-testid={`${testId}-set-max-values`}
						>
							{t('datablock.results.modal.selectAllElements.button.maxValues')}
						</DNBButton>
						<DNBButton
							size="default"
							variant="primary"
							onClick={handleKeepDefaultValuesClick}
							data-testid={`${testId}-keep-default-values`}
						>
							{t('datablock.results.modal.selectAllElements.button.defaultValues')}
						</DNBButton>
					</div>
				</div>
			</Modal>
			<Divider size="large" color={'ColorSlateLighter'} />
			<div className={styles.accordionHeaderContainer}>
				<DNBCheckbox
					size="small"
					id={'select-all'}
					label={t('enriching.step.selectAll')}
					checked={selectAllChecked}
					data-testid={testId + '-select-all-checkbox'}
					onChange={handleSelectAllChange}
				/>
				<div
					data-testid="collapsed-expanded-container"
					className={styles.collapsedExpandedContainer}
					onClick={() => setExpanded(!expanded)}
				>
					<div data-testid="text-collapsed-expanded" className={styles.textCollapsedExpanded}>
						{expanded ? t('accordion.collapse.all') : t('accordion.expand.all')}
					</div>
					<div className={expanded ? styles.bottomArrow : styles.rightArrow} />
				</div>
			</div>

			{isErrorBannerVisible && mandatoryIdArray.length > 0 && (
				<div className={styles.errorBanner}>
					<div className={styles.leftsideInfo}>
						<svg
							className={styles.errorIcon}
							focusable="false"
							aria-hidden="true"
							viewBox="0 0 24 24"
							data-testid="ErrorOutlineIcon"
						>
							<path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
						</svg>
						<div className={styles.errorBannerText}>{t('required.selections.missing')}</div>
					</div>
					<div className={styles.errorBannerResolve}>
						<a className={styles.anchorUnderline} onClick={resolve}>
							{t('resolve')}
						</a>
					</div>
				</div>
			)}

			<div className={styles.listContainer}>
				<InfiniteScroll
					dataLength={displayedItems}
					next={() => fetchMoreData(filteredElementList, displayedItems)}
					loader={t('loading')}
					hasMore={filteredElementList.length > displayedItems}
					height={490}
				>
					{elementsToDisplay
						.filter((e, i) => i <= displayedItems)
						.map((element, index) => (
							<div key={element.elementId + '-div-container'} className={styles.checkboxWrapper}>
								<Accordion
									testId={'accordionItem-' + index}
									allowMultipleOpen={true}
									id={testId + '-accordionItem-' + index}
									expanded={expanded}
								>
									<AccordionHeader
										title={toSentenceCase(element.displayName)}
										isOpen={true}
										controls={testId + '-accordion-panel' + index}
										additionalText={
											element.childs && element.childs.length > 0
												? generateAdditionalTextString(
														element.childs,
														flattenSelectedElements,
														t,
														flattenDefaultElements,
														element.considerChildrenForCount
												  )
												: ''
										}
										stickyAdditionalText={true}
									>
										<div className={styles.checkboxContainer}>
											<DNBCheckbox
												size="small"
												id={t(element.elementId) as string}
												label={''}
												checked={isElementChecked(
													flattenSelectedElements,
													element.elementId,
													flattenDefaultElements,
													true,
													element
												)}
												disabled={isElementDisabled(
													element.elementId,
													flattenDefaultElements,
													activeTradeUp,
													enableEmailVerification,
													EVIDS,
													disabledArrayElements
												)}
												data-testid={testId + '-dbes-cb' + index}
												onChange={(e) => onChangeElement(e, element)}
												indeterminate={isElementSelectedInArray(element)}
											/>
										</div>
									</AccordionHeader>
									{element.childs !== undefined ? (
										<AccordionPanel
											id={testId + '-accordion-panel' + index}
											isOpen={true}
											existControlElement={true}
										>
											<ElementTree
												index={index}
												element={element}
												testId={testId}
												selectedElementList={flattenSelectedElements}
												onChange={onChangeElement}
												defaultElements={flattenDefaultElements}
												showBlockInformation={showBlockInformation}
												onDropSelectorChange={(newSelectedElements) => {
													toggleElement(newSelectedElements, true)
												}}
												mandatoryIdArray={mandatoryIdArray}
												setMandatoryIdArray={setMandatoryIdArray}
												errorBannerVisibility={errorBannerVisibility}
											/>
										</AccordionPanel>
									) : (
										<AccordionPanel id={testId + '-accordion-panel' + index}>
											<div>
												<DataBlockElementSelector
													element={element}
													dataBlockName={element.blockId || ''}
													level={element.level || ''}
													showBlockInformation={showBlockInformation}
												/>
											</div>
										</AccordionPanel>
									)}
								</Accordion>
							</div>
						))}
				</InfiniteScroll>
			</div>
		</div>
	)
}
