import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox } from '../../local-core-ui'
import styles from './match-inclusion.module.scss'

interface MatchInclusionI {
	selectedOptions: Array<string>
	updateInclusion: (updatedOptions: Array<string>) => void
	testId: string
	readonly?: boolean
	enableNonMarketableExclusion?: boolean
}

export const MatchInclusion = ({
	selectedOptions,
	updateInclusion,
	testId,
	readonly = false,
	enableNonMarketableExclusion = false
}: MatchInclusionI): ReactElement => {
	const { t } = useTranslation()
	const exclusionCriteria: { [key: string]: boolean } = {
		NonHeadQuarters: true,
		OutOfBusiness: true,
		Unreachable: true,
		NonMarketable: enableNonMarketableExclusion,
		Undeliverable: true
	}

	const toggleElement = (e: ChangeEvent<HTMLInputElement>, option: string) => {
		exclusionCriteria[option] = e.target.checked
		const updatedOptions = Object.keys(exclusionCriteria).filter(
			(exclusionValue) => !exclusionCriteria[exclusionValue]
		)
		updateInclusion(updatedOptions)
	}

	selectedOptions.forEach((exclusionValue) => (exclusionCriteria[exclusionValue] = false))

	return (
		<div className={styles.matchRuleCustomInclusion}>
			{Object.keys(exclusionCriteria).map((criteria, index) => {
				if (!enableNonMarketableExclusion && criteria == 'NonMarketable') {
					return null
				} else {
					return (
						<div key={criteria + index} className={styles.checkboxContainer}>
							<CheckBox
								id={criteria}
								value={criteria}
								label={t(`match.rule.custom.inclusion.${criteria}`) as string}
								checked={exclusionCriteria[criteria]}
								onChange={(e) => toggleElement(e, criteria)}
								testId={`${testId}-${criteria}`}
								disabled={readonly}
							/>
						</div>
					)
				}
			})}
		</div>
	)
}
