import { ColorChart5, ColorRed } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../local-core-ui'
import styles from './licenses-table.module.scss'

export interface typeBenefit {
	benefit: string
	values: Array<string | boolean | number>
	note?: string | undefined
}
export interface groupRow {
	groupName: string
	rows: Array<typeBenefit>
}

interface licensesTableProps {
	columns: Array<{ id: string; headerName: string | '' }>
	groupRows: Array<groupRow>
}
export const LicensesTable: FC<licensesTableProps> = ({ columns, groupRows }: licensesTableProps) => {
	const { t } = useTranslation()
	return (
		<div>
			<table className={styles.tableLicenses}>
				<thead>
					<tr key={'header-table-licenses'}>
						{columns.map((column, idx) => (
							<th key={`column-${idx}`}>{column.headerName}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{groupRows.map((groupRow) => {
						return (
							<>
								{groupRow.rows.map((row: typeBenefit, idxB: number) => {
									return (
										<>
											{idxB === 0 && groupRow.groupName !== 'none' && (
												<tr className={styles.heading}>
													<td colSpan={3}>
														{t(`page.settings.general.group.benefit.${groupRow.groupName}`)}
													</td>
												</tr>
											)}
											<tr key={`row-${row.benefit}-${idxB}`}>
												<td>
													{t(`page.settings.general.benefit.${row.benefit}`)}
													{row.note}
												</td>
												{row.values.map((dataLicense, idxV) => {
													return (
														<td key={`row-${row.benefit}-${idxB}-${idxV}`}>
															{typeof dataLicense === 'string' ? (
																dataLicense
															) : dataLicense ? (
																<div className={styles.iconCheck}>
																	<Icon
																		type={'simple-check'}
																		color={ColorChart5}
																		size={'mini'}
																	/>
																</div>
															) : (
																<div className={styles.iconNo}>
																	<Icon type={'x'} size={'small'} color={ColorRed} />
																</div>
															)}
														</td>
													)
												})}
											</tr>
										</>
									)
								})}
							</>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
