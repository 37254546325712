import { AxiosResponse } from 'axios'
import { MatchRule, MatchType } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setMatchDefinitionValidated, updateLoadingNextStep, updateMatchRule } from '../actions'

export const createOrUpdateMatchRules =
	(enableNonMarketable: boolean): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const matchRules = getState().projectWizard.currentProject.matchRules

		if (matchRules.length > 0) {
			dispatch(updateLoadingNextStep(true))
			const url = '/pls/matchrules/'
			const apiClient = getApiClient4Thunks(dispatch)
			const promiseArray: Array<Promise<unknown>> = []

			matchRules.forEach((matchRuleInfo, index) => {
				const matchRuleInfoCopy = JSON.parse(JSON.stringify(matchRuleInfo))

				if (!matchRuleInfoCopy.matchRule.exclusionCriterion) {
					if (enableNonMarketable) {
						matchRuleInfoCopy.matchRule.exclusionCriterion = []
					} else {
						matchRuleInfoCopy.matchRule.exclusionCriterion = ['NonMarketable']
					}
				} else {
					if (!enableNonMarketable) matchRuleInfoCopy.matchRule.exclusionCriterion.push('NonMarketable')
				}
				if (matchRuleInfoCopy.matchRule.exclusionCriterion) {
					matchRuleInfoCopy.matchRule.exclusionCriterion =
						matchRuleInfoCopy.matchRule.exclusionCriterion.filter(
							(criteria: string, index: number) =>
								matchRuleInfoCopy.matchRule.exclusionCriterion.indexOf(criteria) === index
						)
				}

				if (!matchRuleInfoCopy.useEasyMatchString && matchRuleInfo.matchQualityType === MatchType.EASY) {
					matchRuleInfoCopy.matchRule.acceptCriterion.MatchGradePatterns = []
				}
				promiseArray.push(
					new Promise<void>((resolve, reject) => {
						if (matchRuleInfoCopy.matchRule.matchRuleId) {
							apiClient
								.put(url, matchRuleInfoCopy.matchRule)
								.then((updatedMatchRule: AxiosResponse<MatchRule>) => {
									dispatch(
										updateMatchRule(index, {
											...matchRuleInfoCopy,
											matchRule: updatedMatchRule.data
										})
									)
									resolve()
								})
								.catch((e) => {
									reject(e)
								})
						} else {
							apiClient
								.post(url, matchRuleInfoCopy.matchRule)
								.then((createdMatchRule: AxiosResponse<MatchRule>) => {
									dispatch(
										updateMatchRule(index, {
											...matchRuleInfoCopy,
											matchRule: createdMatchRule.data
										})
									)
									resolve()
								})
								.catch((e) => {
									reject(e)
								})
						}
					})
				)
			})

			return Promise.all(promiseArray)
				.then(() => {
					dispatch(setMatchDefinitionValidated())
					dispatch(updateLoadingNextStep(false))
				})
				.catch((e) => {
					console.error(e)
					dispatch(updateLoadingNextStep(false))
					throw e
				})
		} else {
			return new Promise<void>((resolve, reject) => reject())
		}
	}
