import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import information from '../../../../../assets/images/info.svg'
import lock from '../../../../../assets/images/lock.svg'
import mandatoryElements from '../../../../../components/data-block-searcher/arrayMandatoryElesAndAlerts.json'
import { ElementUIFacade } from '../../../../../components/data-block-searcher/ElementUIFacade'
import industriesCodeList from '../../../../../components/data-block-searcher/industries-codes.json'
import { Accordion, AccordionHeader, AccordionPanel, CheckBox } from '../../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../store'
import { addElementInPreview, removeElementInPreview } from '../../../../../store/projectWizard/actions'
import { Element } from '../../../../../types'
import tradeUpList from '../../select-your-data/tradeup-elements.json'
import styles from './data-block-previewer-enrichment.module.scss'

interface DataBlockPreviewerEnrichmentProps {
	title?: string
	selectedElements: Array<Element>
	defaultElements?: Array<Element>
	readOnly?: boolean
	elementsSelectedByDefault: Array<string>
	elementsBelongingToRequiredGroup: Array<string>
	checkboxWithDropdownPatternGroupedElements: Array<{ id: string; elements: Array<string> }>
	blockId: string
}

export interface GroupedElementsByLevel {
	[key: string]: Array<Element>
}
export const DataBlockPreviewerEnrichment = ({
	title,
	selectedElements,
	defaultElements,
	readOnly,
	elementsSelectedByDefault,
	elementsBelongingToRequiredGroup,
	checkboxWithDropdownPatternGroupedElements,
	blockId
}: DataBlockPreviewerEnrichmentProps): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [expanded, setExpanded] = useState<boolean>(true)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const testId = 'enrichmentPreviewer'
	const sortByDisplayName = (a: Element, b: Element) => {
		return new Intl.Collator(navigator.language, { numeric: true }).compare(a.displayName, b.displayName)
	}

	const groupElementsByLevel = () => {
		const groupedElementsByLevel: GroupedElementsByLevel = {}
		selectedElements.forEach((eleObj: Element) => {
			if (groupedElementsByLevel[eleObj.level]) {
				groupedElementsByLevel[eleObj.level].push(eleObj)
			} else {
				groupedElementsByLevel[eleObj.level] = []
				groupedElementsByLevel[eleObj.level].push(eleObj)
			}
		})
		return groupedElementsByLevel
	}
	const groupedElementsByLevel = groupElementsByLevel()

	const dataBlockElements = (key: string) => {
		const elementsToRender: Array<Element> = groupedElementsByLevel[key as keyof typeof groupedElementsByLevel]
		const temporalSelectedElements: Array<Element> = mappingChilds(elementsToRender)
		temporalSelectedElements.sort(sortByDisplayName)
		return temporalSelectedElements.map((element: ElementUIFacade) => {
			return (
				<li key={element.elementId}>
					<div>
						<div data-testid="data-block-container" className={styles.dataBlockPreviewerEnrichmentElement}>
							<CheckBox
								id={element.elementId}
								label={element.displayName}
								onChange={(e) => handleOnchange(e, element)}
								checked={isElementChecked(element)}
								disabled={
									readOnly ||
									isElementCheckedByDefault(element) ||
									isElementCheckedMandatory(element) ||
									isElementTradeUp(element.elementId) ||
									isRemovedFromDeprecated(element.elementId) ||
									isElementCheckedMandatoryforAging(element.elementId)
								}
								testId={`checkbox-${element.elementId}-test`}
							/>
						</div>
						{element.childs?.map((elementNested) => (
							<div
								key={element.elementId + '-container-' + elementNested.elementId}
								className={styles.dataBlockPreviewerEnrichmentElement}
								data-testid="element-container"
							>
								<li
									data-testid="element-child"
									key={element.elementId + '-child-' + elementNested.elementId}
								>
									<label data-testid="element-label">{elementNested.displayName}</label>
								</li>
							</div>
						))}
					</div>
				</li>
			)
		})
	}

	const getIndustriesCodeListChilds = (elementsToRender: Array<Element>, temporalArraySelected: Array<Element>) => {
		industriesCodeList.forEach((industryCode) => {
			const elementChild: Array<Element> = []
			elementsToRender.map((selectedElement) => {
				const isNestedElement = selectedElement.elementId.includes(industryCode)
				const idx = temporalArraySelected.findIndex(
					(temporalSelected) => selectedElement.elementId === temporalSelected.elementId
				)
				if (isNestedElement && idx > -1) {
					elementChild.push(selectedElement)
					temporalArraySelected.splice(idx, 1)
				}
			})

			if (elementChild.length) {
				const nestedArray: ElementUIFacade = {
					elementId: industryCode,
					displayName: t(industryCode),
					childs: elementChild
				}
				temporalArraySelected.push(nestedArray)
			}
		})
	}

	const getCheckboxGroupWithDropdownPatternElements = (
		elementsToRender: Array<Element>,
		temporalArraySelected: Array<Element>
	) => {
		checkboxWithDropdownPatternGroupedElements.forEach((groupedElements) => {
			const childrenElements: Array<Element> = []
			const elementsToGroup = elementsToRender.filter((selectedElement) =>
				groupedElements.elements.includes(selectedElement.elementId)
			)
			if (elementsToGroup.length > 0) {
				elementsToGroup.forEach((elementInGroup) => {
					const elementIndex = temporalArraySelected.findIndex(
						(tempSelected) => tempSelected.elementId === elementInGroup.elementId
					)
					if (elementIndex > -1) {
						childrenElements.push(elementInGroup)
						temporalArraySelected.splice(elementIndex, 1)
					}
				})
				if (childrenElements.length > 0) {
					temporalArraySelected.push({
						elementId: groupedElements.id,
						displayName: childrenElements[0].displayName.replace(/[0-9]/g, ''),
						childs: childrenElements
					} as ElementUIFacade)
				}
			}
		})
	}

	const mappingChilds = (elementsToRender: Array<Element>) => {
		const temporalArraySelected: Array<Element> = [...elementsToRender]
		getIndustriesCodeListChilds(elementsToRender, temporalArraySelected)
		getCheckboxGroupWithDropdownPatternElements(elementsToRender, temporalArraySelected)
		return temporalArraySelected
	}

	const level = () => {
		if (selectedElements === undefined || selectedElements.length == 0) return 0
		return (
			selectedElements.reduce((prevElement: ElementUIFacade, currentElement: ElementUIFacade) =>
				Number(prevElement.level) > Number(currentElement.level) ? prevElement : currentElement
			) as ElementUIFacade
		).level
	}

	const handleOnchange = (e: ChangeEvent<HTMLInputElement>, element: ElementUIFacade) => {
		if (e.target.checked) {
			if (blockId === 'companyinfo') {
				const isIndustry = industriesCodeList.find((industry) => industry === element.elementId)
				if (isIndustry) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.includes(element.elementId)) {
							dispatch(addElementInPreview(selectedElement))
						}
					})
					return
				}
			} else if (element.childs) {
				element.childs.forEach((childElement) => {
					const elementToRemove = selectedElements.find(
						(selectedElement) => selectedElement.elementId === childElement.elementId
					)
					if (elementToRemove) {
						dispatch(addElementInPreview(elementToRemove))
					}
				})
				return
			}
			dispatch(addElementInPreview(element))
		} else {
			if (blockId === 'companyinfo') {
				const isIndustry = industriesCodeList.find((industry) => industry === element.elementId)
				if (isIndustry) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.includes(element.elementId)) {
							dispatch(removeElementInPreview(selectedElement))
						}
					})
					return
				}
			} else if (element.childs) {
				element.childs.forEach((childElement) => {
					const elementToRemove = selectedElements.find(
						(selectedElement) => selectedElement.elementId === childElement.elementId
					)
					if (elementToRemove) {
						dispatch(removeElementInPreview(elementToRemove))
					}
				})
				return
			}
			dispatch(removeElementInPreview(element))
		}
	}

	const isElementTradeUp = (elementId: string) => {
		return !!tradeUpList?.find((elem) => elem.elements.find((el) => el.elementId === elementId))
	}
	const isElementCheckedByDefault = (elementToReview: ElementUIFacade) => {
		return (
			!!defaultElements?.find((element) => element.elementId === elementToReview.elementId) ||
			elementsSelectedByDefault.includes(elementToReview.elementId) ||
			(elementToReview.childs &&
				elementToReview.childs.some((childElement) =>
					elementsSelectedByDefault.includes(childElement.elementId)
				))
		)
	}

	const isRemovedFromDeprecated = (elementId: string) => {
		return !!projectWizardState.enrichmentPreviewSubStep.removedElements.find(
			(removedElement) => removedElement.elementId === elementId
		)
	}

	const isElementCheckedMandatory = (elementToReview: ElementUIFacade) => {
		const mandatoryElementsToPreview = mandatoryElements['mandatoryToPreview']
		const elementSplit = elementToReview.elementId.split('_')
		const suffix = elementSplit[elementSplit.length - 1]
		return (
			mandatoryElementsToPreview.indexOf(elementToReview.elementId.replace(new RegExp(suffix + '$'), '')) > -1 ||
			elementsBelongingToRequiredGroup.includes(elementToReview.elementId) ||
			(elementToReview.childs &&
				elementToReview.childs.some((childElement) =>
					elementsSelectedByDefault.includes(childElement.elementId)
				))
		)
	}

	const isElementCheckedMandatoryforAging = (elementId: string) => {
		const mandatoryElementsToPreview = mandatoryElements['mandatoryToPreview']
		const elementSplit = elementId.split('_')
		if (elementSplit[0] && elementSplit[1]) {
			const elementToSearch = elementSplit[0] + '_' + elementSplit[1] + '_'
			return mandatoryElementsToPreview?.indexOf(elementToSearch) > -1
		}
	}

	const isElementChecked = (elementToReview: ElementUIFacade) => {
		if (defaultElements && isElementCheckedByDefault(elementToReview)) {
			return true
		}
		const isIndustry = industriesCodeList.find((industry) => industry === elementToReview.elementId)
		if (isIndustry) {
			return !isRemoved(elementToReview.elementId)
		} else if (elementToReview.childs) {
			return !projectWizardState.enrichmentPreviewSubStep.removedElements.some((removedElement) => {
				return elementToReview.childs?.some(
					(childElement) => childElement.elementId === removedElement.elementId
				)
			})
		} else {
			return !projectWizardState.enrichmentPreviewSubStep.removedElements.find(
				(removedElement) => removedElement.elementId === elementToReview.elementId
			)
		}
	}

	const isRemoved = (elementId: string) => {
		return projectWizardState.enrichmentPreviewSubStep.removedElements.find((removedElement) => {
			return removedElement.elementId.includes(elementId)
		})
	}

	const reducer = (accumulator: number, element: Element) => {
		if (
			projectWizardState.enrichmentPreviewSubStep.removedElements.find(
				(removedElement) => removedElement.elementId == element.elementId
			)
		)
			return accumulator
		else return accumulator + 1
	}

	const countSelectedElements = (levelElments: Array<Element>) => {
		return levelElments.reduce(reducer, 0)
	}

	return (
		<div data-testid="data-block-previewer-container" className={styles.dataBlockPreviewerEnrichmentContainer}>
			<div data-testid="data-block-previewer-header" className={styles.dataBlockPreviewerEnrichmentHeader}>
				<div className={styles.accordionHeaderContainer}>
					<div className={styles.containerImgAndTitle}>
						{title && defaultElements ? <img width="12px" src={lock} alt="view elements lock" /> : null}
						<h4
							data-testid="title-enrichment-data-blocks"
							className={styles.dataBlockPreviewerEnrichmentTitle}
						>
							{title}
						</h4>
					</div>
					<div
						data-testid="collapsed-expanded-container"
						className={styles.collapsedExpandedContainer}
						onClick={() => setExpanded(!expanded)}
					>
						<div data-testid="text-collapsed-expanded" className={styles.textCollapsedExpanded}>
							{expanded ? t('accordion.collapse.all') : t('accordion.expand.all')}
						</div>
						<div className={expanded ? styles.bottomArrow : styles.rightArrow} />
					</div>
				</div>
				{blockId === 'entityresolution' && (
					<div
						data-testid="data-block-previewer-box-info-entityresolution"
						className={styles.dataBlockPreviewerBoxInformation}
					>
						<div className={styles.informationContainer}>
							<img className={styles.informationIcon} src={information} alt={'information'} />
							<div>{t('dataBlock.previewer.information.entityResolution')}</div>
						</div>
					</div>
				)}
				{Object.keys(groupedElementsByLevel).map((key, index) => {
					const levelElments = groupedElementsByLevel[key as keyof typeof groupedElementsByLevel]
					const levelLabelKey =
						['0', '1', '2', '3', '4', '5'].findIndex((level) => level === key) > -1
							? 'data.block.tile.level'
							: `data.block.tile.level.sideBlock.${key}`
					const levelValue = t(levelLabelKey, { level: key })
					const levelValueWithCount =
						levelValue +
						' - ' +
						t('data.block.tile.selected', { selected: countSelectedElements(levelElments) })
					return (
						<div id="accordionContainer" key={index}>
							<Accordion
								testId={'accordionItem'}
								allowMultipleOpen={true}
								id={'enrichment' + '-accordionItem-' + index}
								expanded={expanded}
							>
								<AccordionHeader
									title={levelValueWithCount}
									isOpen={true}
									controls={testId + '-accordion-panel'}
									stickyAdditionalText={true}
								></AccordionHeader>
								<AccordionPanel
									id={testId + '-accordion-panel'}
									isOpen={true}
									existControlElement={true}
								>
									<div className={styles.dataBlockPreviewerEnrichmentContainerList} key={index}>
										<ul
											data-testid="data-block-previewer-enrichment-elements-list"
											className={styles.dataBlockPreviewerEnrichmentElementsList}
										>
											{dataBlockElements(key)}
										</ul>
									</div>
								</AccordionPanel>
							</Accordion>
						</div>
					)
				})}
			</div>
		</div>
	)
}
