import { DNBTypography } from '@dnb-uux-design-system/react'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LicenseType } from '../../../store/session/types/LicenseType'
import styles from './current-license-detail.module.scss'

interface currentLicenseDetailProps {
	benefitsCurrentLicense: Array<{ name: string; description: string }>
	currentLicense: LicenseType
}
export const CurrentLicenseDetail: FC<currentLicenseDetailProps> = ({
	benefitsCurrentLicense,
	currentLicense
}: currentLicenseDetailProps) => {
	const { t } = useTranslation()

	return (
		<div
			className={cx(styles.currentLicenseContainer, {
				[styles.withoutData]: currentLicense === LicenseType.LEGACY
			})}
		>
			{currentLicense !== LicenseType.LEGACY ? (
				<>
					<DNBTypography variant="h5">{t('page.settings.general.section.details.title')}</DNBTypography>
					<div className={styles.descriptionCurrentLicense}>
						{benefitsCurrentLicense.map((benefit, idxCurrentBenefit) => (
							<div key={`currentBenefit-${idxCurrentBenefit}`}>
								<div>
									<DNBTypography variant={'compact-medium'}>{benefit.name}</DNBTypography>
								</div>
								<div>
									<DNBTypography variant={'compact-body'}>{benefit.description}</DNBTypography>
								</div>
							</div>
						))}
					</div>
				</>
			) : (
				<span className={styles.textWithoutData}>{t('setting.general.license.noData')}</span>
			)}
		</div>
	)
}
