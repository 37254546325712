import { AxiosInstance, AxiosResponse } from 'axios'
import { Connection } from './getConnections'

export const getConnectionsStatus = async (
	apiClient: AxiosInstance,
	connectionNames: string[]
): Promise<Connection[]> => {
	const ConnectionsList: Connection[] = []
	const maxItemsPerCall = 20
	let allConnectionsReceived = false
	let firstItem = 0
	let lastItem = maxItemsPerCall

	while (!allConnectionsReceived) {
		const url = `/pls/integration/connection/status`
		await apiClient
			.post(url, { connectionNames: connectionNames.slice(firstItem, lastItem) })
			.then((response: AxiosResponse<Connection[]>) => {
				const connections: Connection[] = response.data

				if (connections.length === 0) {
					allConnectionsReceived = true
				} else {
					ConnectionsList.push(...connections)
					if (connections.length < maxItemsPerCall) {
						allConnectionsReceived = true
					} else {
						firstItem = firstItem + maxItemsPerCall
						lastItem = lastItem + maxItemsPerCall
					}
				}
			})
			.catch(() => {
				allConnectionsReceived = true
			})
	}

	return ConnectionsList
}
