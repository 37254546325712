import { DNBSelect, DNBSelectOption } from '@dnb-uux-design-system/react'
import { includes, some, toNumber } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToolTip } from '../../local-core-ui'
import { Pattern } from '../../types'
import arrayPrefixes from '../data-block-searcher/array-prefixes.json'
import mandatoryElements from '../data-block-searcher/arrayMandatoryElesAndAlerts.json'
import { ElementUIFacade } from './ElementUIFacade'
import { isElementChecked } from './helpers/element-tree-helpers'
import styles from './select.module.scss'
import subjectCommentsPrefixes from './subject-comments-element-prefixes.json'

interface SelectProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const Select = ({
	element,
	selectedElementList,
	onDropSelectorChange,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: SelectProps): ReactElement => {
	const { t } = useTranslation()
	const [isDisabledSelect, setIsDisabledSelect] = useState(!!element.dropdownDisabled)

	const removeMandatoryCheck = (name: string) => {
		if (mandatoryIdArray.indexOf(name) >= 0) {
			mandatoryIdArray.splice(mandatoryIdArray.indexOf(name), 1)
			errorBannerVisibility(mandatoryIdArray)
			setMandatoryIdArray(mandatoryIdArray)
		}
	}

	const addChildrenBySelector = (): Array<ElementUIFacade> => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const childrenSelectorValueRef = element.maximumElementsInOutput
		if (childrenSelectorValueRef) {
			const blockChildren = element.childs || []
			if (element.pattern === Pattern.CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN) {
				element.checkboxGroupOptions?.forEach((option) => {
					if (option.isChecked) {
						blockChildren.forEach((displayedElement) => {
							const elementsFromOption = displayedElement.childs?.filter((element) =>
								option.elements?.includes(element.elementId)
							)
							elementsFromOption?.forEach((elementToReview) => {
								const splitElement = elementToReview.elementId.split('_')
								const elementNumber = toNumber(splitElement[splitElement.length - 1])
								const selectedElementIndex = newSelectedElements.findIndex(
									(currentSelectedElement) =>
										currentSelectedElement.elementId === elementToReview.elementId
								)
								if (elementNumber <= toNumber(childrenSelectorValueRef.current)) {
									const isDisplayedElementSelected = newSelectedElements.some((selectedElement) =>
										displayedElement.childs?.some(
											(element) => element.elementId === selectedElement.elementId
										)
									)
									if (selectedElementIndex < 0 && isDisplayedElementSelected) {
										newSelectedElements.push(elementToReview)
									}
								} else if (selectedElementIndex >= 0) {
									newSelectedElements.splice(selectedElementIndex, 1)
								}
							})
						})
					}
				})
			} else if (element.isNestedSelector || element.parent?.isNestedSelector) {
				const maxElementsInParentBlockOutput = element.maximumElementsInOutput?.current ?? 1

				if (element.isNestedSelector) {
					const currentElementsInRootOutput = element.maximumElementsInOutput?.current ?? 0
					const totalElementsInRootDropdown = element.outputDropdownOptions?.length ?? 0
					blockChildren.forEach((blockChild) => {
						if (
							blockChild.outputDropdownOptions !== undefined &&
							blockChild.outputDropdownOptions.length > 0 &&
							blockChild.childs
						) {
							const maxElementsInChildOutput = blockChild.childs[0].maximumElementsInOutput?.current ?? 1

							blockChild.childs.forEach((level2Child) => {
								if (
									element.maximumElementsInOutput &&
									element.maximumElementsInOutput?.current > 0 &&
									level2Child.maximumElementsInOutput?.current === 0
								) {
									level2Child.maximumElementsInOutput.current = 1
								}
								for (let i = 1; i <= maxElementsInParentBlockOutput; i++) {
									level2Child.childs?.forEach((deepChild) => {
										for (let j = 1; j <= maxElementsInChildOutput; j++) {
											const elementIdStr = `${blockChild.elementId}${i}_${level2Child.elementId}${j}`
											const elementAlreadySelected = some(newSelectedElements, (element) =>
												includes(element.elementId, elementIdStr)
											)

											if (deepChild.elementId.includes(elementIdStr) && !elementAlreadySelected) {
												newSelectedElements.push(deepChild)
											}
										}
									})
								}
							})
						} else {
							blockChild.childs?.forEach((deepChild) => {
								for (let i = 1; i <= totalElementsInRootDropdown; i++) {
									const elementIdStr = `${blockChild.elementId}${i}`
									const elementAlreadySelected = some(newSelectedElements, (element) =>
										includes(element.elementId, elementIdStr)
									)
									const shouldBeRemoved =
										currentElementsInRootOutput < totalElementsInRootDropdown &&
										i > currentElementsInRootOutput &&
										elementAlreadySelected
									const shouldBeAdded =
										deepChild.elementId.includes(elementIdStr) &&
										!elementAlreadySelected &&
										i <= currentElementsInRootOutput

									if (shouldBeRemoved) {
										const elementToRemoveIndex = newSelectedElements.findIndex(
											(selectedElement) => selectedElement.elementId === elementIdStr
										)
										newSelectedElements.splice(elementToRemoveIndex, 1)
									} else if (shouldBeAdded) {
										newSelectedElements.push(deepChild)
									}
								}
							})
						}
					})
				}

				if (element.parent?.isNestedSelector) {
					const parent = element.parent
					const maxElementsInParent = parent.maximumElementsInOutput?.current || 0
					const maxElementsInCurrent = element.maximumElementsInOutput?.current || 0
					if (parent.maximumElementsInOutput?.current === 0) {
						parent.maximumElementsInOutput.current = 1
					}

					let subjectCommentElements: ElementUIFacade[] = []
					const level2SelectedElements: ElementUIFacade[] = []
					subjectCommentElements = selectedElementList.filter((element) =>
						element.elementId.includes(subjectCommentsPrefixes.subjectCommentsId[0])
					)

					const allLevel2Childs: ElementUIFacade[] = []
					if (element.childs) {
						element.childs?.reduce((acc: ElementUIFacade[], level2Child: ElementUIFacade) => {
							if (level2Child.childs) {
								return acc.concat(level2Child.childs)
							} else {
								return acc
							}
						}, [] as ElementUIFacade[])
					}
					const selectedPrefixes: string[] = []
					subjectCommentsPrefixes.level2Prefixes.forEach((prefix) => {
						let elementIncluded = false
						subjectCommentElements.forEach((element) => {
							if (element.elementId.includes(prefix)) {
								level2SelectedElements.push(element)
								elementIncluded = true
							}
						})

						if (elementIncluded) {
							selectedPrefixes.push(prefix)
						}
					})
					if (allLevel2Childs) {
						allLevel2Childs.forEach((level2Element) => {
							selectedPrefixes.forEach((selectedPrefix) => {
								for (let i = 1; i <= maxElementsInParent; i++) {
									for (let j = 1; j <= maxElementsInCurrent; j++) {
										const elementIdStr = `${subjectCommentsPrefixes.level1Prefixes[1]}${i}_${selectedPrefix}${j}`
										const elementAlreadySelected = some(newSelectedElements, (element) =>
											includes(element.elementId, elementIdStr)
										)
										if (level2Element.elementId.includes(elementIdStr) && !elementAlreadySelected) {
											newSelectedElements.push(level2Element)
										}
									}
								}
							})
						})
					}
				}
			} else {
				blockChildren.forEach((blockChild) => {
					const isChildChecked = isElementChecked(
						selectedElementList,
						blockChild.elementId,
						undefined,
						true,
						blockChild
					)
					if (isChildChecked || blockChild.isSelectedByDefault) {
						blockChild.childs?.forEach((elem, index) => {
							const selectedElementIndex = newSelectedElements.findIndex(
								(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
							)
							if (index < childrenSelectorValueRef.current && selectedElementIndex < 0) {
								newSelectedElements.push(elem)
							} else if (index >= childrenSelectorValueRef.current && selectedElementIndex >= 0) {
								newSelectedElements.splice(selectedElementIndex, 1)
							}
						})
					}
				})
			}
		}
		return newSelectedElements
	}

	const checkMandatoryStatus = (option: ElementUIFacade) => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const prefixes: Array<string> = mandatoryElements[option?.elementId as keyof typeof mandatoryElements]
		const totalPrefixes: Array<string> = arrayPrefixes[option?.elementId as keyof typeof arrayPrefixes]
		const mandatoryIdExists = totalPrefixes?.some((element) => prefixes?.includes(element))
		if (option?.elementId && mandatoryIdExists && prefixes) {
			const mandatoryError = newSelectedElements.some(
				(element) =>
					prefixes?.indexOf(element.elementId.substring(0, element.elementId.lastIndexOf('_')) + '_') >= 0
			)
			if (!mandatoryError && mandatoryIdArray.indexOf(option.displayName) < 0) {
				setMandatoryIdArray([...mandatoryIdArray, option.displayName])
				errorBannerVisibility([...mandatoryIdArray, option.displayName])
			} else {
				if (mandatoryError && mandatoryIdArray.indexOf(option.displayName) >= 0) {
					const updatedMandatoryArray = mandatoryIdArray.filter((ele) => {
						return ele !== option?.displayName
					})
					setMandatoryIdArray(updatedMandatoryArray)
					errorBannerVisibility(updatedMandatoryArray)
				}
			}
		}
	}

	const handleScrollEvent = () => {
		const listElement = document.querySelector('.MuiSelect-popper')
		const buttonElement = listElement?.parentElement?.firstChild
		buttonElement?.click()
	}

	useEffect(() => {
		const listContainer = document.querySelector('.infinite-scroll-component')
		listContainer && listContainer.addEventListener('scroll', handleScrollEvent)
	}, [])

	useEffect(() => {
		if (element.pattern === Pattern.CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN) {
			setIsDisabledSelect(element.maximumElementsInOutput?.current === 0)
		}
	}, [selectedElementList])

	const updateSelectAndChilds = (element: ElementUIFacade, selection: string) => {
		if (element.maximumElementsInOutput?.current) {
			element.maximumElementsInOutput.current = parseInt(selection)
			if (onDropSelectorChange) {
				onDropSelectorChange(addChildrenBySelector())
			}
			if (element.pattern === Pattern.CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN) {
				if (selection === '0') {
					setIsDisabledSelect(true)
				} else {
					setIsDisabledSelect(false)
				}
			}
			if (selection === '0') {
				removeMandatoryCheck(element.displayName)
			} else {
				checkMandatoryStatus(element)
			}
		}
	}

	const getSelectInput = () => {
		return (
			<DNBSelect
				id={`children-selector-${element.elementId}`}
				size="compact"
				minWidth="8.7rem"
				testId={`t-children-selector-${element.elementId}`}
				value={`${element.maximumElementsInOutput?.current}`}
				listMaxHeight={'20rem'}
				onChange={(_event: any, selection: string) => {
					updateSelectAndChilds(element, selection)
				}}
				disabled={isDisabledSelect}
			>
				<DNBSelectOption key="option-0" value="0">
					-
				</DNBSelectOption>
				{element.outputDropdownOptions !== undefined
					? element.outputDropdownOptions.map(({ value, label }) => (
							<DNBSelectOption key={`option-${value}`} value={value}>
								{label}
							</DNBSelectOption>
					  ))
					: null}
			</DNBSelect>
		)
	}

	return (
		<div className={styles.childrenSelectorContainer}>
			<div className={styles.childrenSelectorQuestion}>
				{t(`${element.elementId}.dropdown.label`, t('how.many'))}
			</div>
			<div className={styles.selectorWrapper}>
				<div className={styles.checkboxContainer}>
					{element.pattern === Pattern.CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN &&
					element.maximumElementsInOutput?.current === 0 ? (
						<ToolTip
							customContent={
								<>
									<div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
										{t('tooltip.checkbox.with.dropdown.pattern')}
									</div>
								</>
							}
							position={'top'}
							testId={'test'}
						>
							{getSelectInput()}
						</ToolTip>
					) : (
						getSelectInput()
					)}
				</div>
				<div className={styles.childrenSelectorText}>
					<div>
						{t('maximum', {
							number: element.outputDropdownOptions ? element.outputDropdownOptions.length : 0
						})}
					</div>
					<div>{t(`${element.elementId}.hint`, '')}</div>
				</div>
			</div>
		</div>
	)
}
