import { TFunction } from 'i18next'
import { LicenseType } from '../../../store/session/types/LicenseType'
import AdvancedLicenseData from './advancedLicense.json'
import BasicLicenseData from './basicLicense.json'
import benefitsByGroup from './benefitsByGroup.json'
import { groupRow, typeBenefit } from './licenses-table'

const licenseHierarchy = [LicenseType.BASIC_LICENSE, LicenseType.ADVANCED_LICENSE]
const arraysByLicense: Record<string, Array<{ benefit: string; value: string | boolean | number; note?: string }>> = {
	[LicenseType.BASIC_LICENSE]: BasicLicenseData,
	[LicenseType.ADVANCED_LICENSE]: AdvancedLicenseData
}
export const getLicensesToShow = (currentLicense: LicenseType) => {
	return mergeArraysByLicense(currentLicense)
}

export const getHeadersTable = (currentLicense: LicenseType, t: TFunction<'translation', undefined, 'translation'>) => {
	const currentLicenseIndex = licenseHierarchy.indexOf(currentLicense)
	const licensesToShow = licenseHierarchy.slice(currentLicenseIndex)
	const headers = [{ id: 'benefit', headerName: '' }]
	licensesToShow.forEach((license) => {
		headers.push({
			id: `license-${license}`.replaceAll(/\s/g, ''),
			headerName:
				t('page.settings.general.column.' + license) +
				(currentLicense === license ? t('page.settings.general.column.currentLevel') : '')
		})
	})
	return headers
}

function headingsAndOrder(benefits: Array<typeBenefit>) {
	const rowsWithHeadings: Array<groupRow> = []
	Object.entries(benefitsByGroup[0]).map((group) => {
		const rowsGroup: Array<typeBenefit> = []
		group[1].map((nameBenefitGroup) => {
			rowsGroup.push(benefits.filter((benefit) => benefit.benefit === nameBenefitGroup)[0])
		})
		const groupWithHeading: groupRow = {
			groupName: group[0],
			rows: rowsGroup
		}
		rowsWithHeadings.push(groupWithHeading)
	})
	return rowsWithHeadings
}
function mergeArraysByLicense(currentLicense: LicenseType) {
	const merged = []
	const mapLicenses: Record<string, typeBenefit> = {}

	// Get the index of the current license in the hierarchy
	const currentLicenseIndex = licenseHierarchy.indexOf(currentLicense)
	// Iterate on the licenses FROM the lowest to the current one.
	licenseHierarchy.slice(currentLicenseIndex).forEach((license) => {
		arraysByLicense[license].forEach((item) => {
			if (!mapLicenses[item.benefit]) {
				mapLicenses[item.benefit] = {
					benefit: item.benefit,
					values: [],
					note: item.note !== undefined ? item.note : ''
				}
			}
			mapLicenses[item.benefit].values.push(item.value)
		})
	})

	// Convert the map to an array of objects
	for (const key in mapLicenses) {
		merged.push(mapLicenses[key])
	}

	return headingsAndOrder(merged)
}
