import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColorScaleRed } from '../../../../design-tokens/build/shared/token-colors.json'
import { Icon, Modal } from '../../local-core-ui'
import styles from './delete-connection-modal.module.scss'

interface DeleteConnectionModalProps {
	openModal: boolean
	setOpenModal: (open: boolean) => void
	onDeleteConnection: () => void
	connectionName: string
	isLoading: boolean
	displayName?: string
}

export const DeleteConnectionModal = ({
	openModal,
	setOpenModal,
	onDeleteConnection,
	connectionName,
	isLoading,
	displayName
}: DeleteConnectionModalProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={styles.deleteModal}>
			<Modal open={openModal} showButtonClose={false} testId="delete-connection-modal" fixWidth={false}>
				<div className={styles.deleteModalContainer}>
					<Icon type={'notification'} color={ColorScaleRed} />
					<div className={styles.deleteTitle}>{t('connections.delete.title')}</div>
					<div className={styles.warningText}>
						<Trans
							i18nKey="connection.delete.description.line1"
							values={{ connectionName: displayName ?? connectionName }}
						/>
					</div>
					<div>
						<div className={`${styles.warningText} ${styles.marginText}`}>
							{t('connection.delete.description.line2.connect.manage')}
						</div>
					</div>
					<div className={styles.warningText}>{t('connection.delete.description.line3')}</div>
					<div className={styles.deleteDivider} />
					<div className={styles.deleteButtons}>
						<DNBButton
							size="small"
							variant="secondary"
							onClick={() => setOpenModal(false)}
							data-testid="cancel-delete"
							disabled={isLoading}
						>
							{t('confirmation.modal.cancel')}
						</DNBButton>
						<DNBButton
							size="small"
							variant="destructive"
							onClick={onDeleteConnection}
							data-testid="delete"
							className={styles.deleteButton}
							disabled={isLoading}
						>
							{t('connection.delete.button')}
						</DNBButton>
					</div>
				</div>
			</Modal>
		</div>
	)
}
