import { DNBBanner, DNBCheckbox, DNBIconButton } from '@dnb-uux-design-system/react'
import { toNumber } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../local-core-ui'
import { CheckboxGroupOption, ElementUIFacade } from '../ElementUIFacade'
import styles from './checkbox-group-with-dropdown-pattern.module.scss'

interface checkboxGroupWithDropdownPatternProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	counterColumns: number
}

export const CheckboxGroupWithDropdownPattern = ({
	element,
	onDropSelectorChange,
	selectedElementList,
	counterColumns
}: checkboxGroupWithDropdownPatternProps): ReactElement => {
	const { t } = useTranslation()
	const [openWarning, setOpenWarning] = useState(false)
	const checkboxOptionsRef = useRef<Array<CheckboxGroupOption>>([])
	const checkboxOptionsSelected2TriggerAlert = element.checkboxGroupOptions?.length

	const isCheckboxOptionSelected = (option: CheckboxGroupOption) => {
		return selectedElementList.some((selectedElement) => option.elements?.includes(selectedElement.elementId))
	}

	if (element.checkboxGroupOptions && element.checkboxGroupOptions?.length > 0) {
		checkboxOptionsRef.current = element.checkboxGroupOptions
		checkboxOptionsRef.current.forEach((option) => {
			option.isChecked = isCheckboxOptionSelected(option)
		})
	}

	useEffect(() => {
		const counterCheckboxOptionsSelected = checkboxOptionsRef.current.filter(
			(checkboxOption) => checkboxOption.isChecked
		).length
		setOpenWarning(counterCheckboxOptionsSelected === checkboxOptionsSelected2TriggerAlert)
	}, [selectedElementList])

	const updateChildrenBySelectedCheckboxOption = (option: CheckboxGroupOption): Array<ElementUIFacade> => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const childrenSelectorValueRef = element.maximumElementsInOutput
		const isFirstOptionSelected =
			checkboxOptionsRef.current.filter((checkboxOption) => checkboxOption.isChecked).length === 1

		if (childrenSelectorValueRef) {
			element.childs?.forEach((displayedElement) => {
				const elementsFromOption = displayedElement.childs?.filter((element) =>
					option.elements?.includes(element.elementId)
				)
				elementsFromOption?.forEach((elementToReview) => {
					const splitElement = elementToReview.elementId.split('_')
					const elementNumber = toNumber(splitElement[splitElement.length - 1])
					if (elementNumber <= toNumber(childrenSelectorValueRef.current)) {
						const selectedElementIndex = newSelectedElements.findIndex(
							(currentSelectedElement) => currentSelectedElement.elementId === elementToReview.elementId
						)
						if (option.isChecked) {
							const isDisplayedElementSelected = newSelectedElements.some((selectedElement) =>
								displayedElement.childs?.some(
									(element) => element.elementId === selectedElement.elementId
								)
							)
							if (selectedElementIndex < 0 && (isDisplayedElementSelected || isFirstOptionSelected)) {
								newSelectedElements.push(elementToReview)
							}
						} else if (selectedElementIndex >= 0) {
							newSelectedElements.splice(selectedElementIndex, 1)
						}
					}
				})
			})
		}
		return newSelectedElements
	}
	const onCheckboxOptionChange = (event: ChangeEvent<HTMLInputElement>, option: CheckboxGroupOption) => {
		let someOptionSelected = false
		checkboxOptionsRef?.current?.forEach((val) => {
			if (val.value === event.target.id) {
				val.isChecked = event.target.checked
			}
			someOptionSelected = someOptionSelected || val.isChecked
		})
		if (element.maximumElementsInOutput) {
			if (element.maximumElementsInOutput.current === 0) {
				element.maximumElementsInOutput.current = 1
			}
			element.checkboxGroupOptions = checkboxOptionsRef?.current
			if (onDropSelectorChange) {
				onDropSelectorChange(updateChildrenBySelectedCheckboxOption(option))
			}
			if (!someOptionSelected) {
				element.maximumElementsInOutput.current = 0
			}
		}
	}

	return (
		<div className={styles.containerGralPattern}>
			<div className={styles.containerHeaderInfo}>
				<div className={styles.titleSection}>{element.checkboxGroupName}</div>
				<span className={styles.descriptionCheckboxGroup}>
					{t('choose.option.checkbox.with.dropdown.pattern', {
						checkboxGroupName: element.checkboxGroupName
					})}
				</span>
			</div>
			{openWarning && (
				<div className={styles.warningContainer}>
					<DNBBanner
						severity={'warning'}
						style={{ height: '57px' }}
						action={
							<DNBIconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setOpenWarning(false)
								}}
							>
								<div>
									<Icon testId="close-warning-classications" type={'x'} />
								</div>
							</DNBIconButton>
						}
					>
						<p>
							{t('selecting.all.options.checkbox.with.dropdown.pattern', {
								counterColumns: counterColumns
							})}
						</p>
					</DNBBanner>
				</div>
			)}
			<div className={styles.containerCheckboxList}>
				{element.checkboxGroupOptions?.map((checkboxOption: CheckboxGroupOption, index) => {
					return (
						<DNBCheckbox
							id={checkboxOption.id}
							checked={
								checkboxOptionsRef.current.find(
									(optionRef: CheckboxGroupOption) => optionRef.id === checkboxOption.id
								)?.isChecked
							}
							label={t(checkboxOption.label)}
							indeterminate={false}
							data-testid={checkboxOption.id}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								onCheckboxOptionChange(e, checkboxOption)
							}}
							key={`${index}_${checkboxOption}`}
							size="small"
						/>
					)
				})}
			</div>
		</div>
	)
}
