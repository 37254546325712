import { AxiosInstance, AxiosResponse } from 'axios'
import {
	GetMatchQualityMode,
	GetMatchRulesResponse,
	MatchingApproach,
	MatchStyle,
	MatchType,
	Source
} from '../../../types'
import { TDispatch } from '../../index'
import { updateCurrentProjectAction } from '../actions'
import { MatchRuleInfo } from '../types'

export const readMatchingInfoIntoStore = async (
	selectedSource: Source,
	apiClient: AxiosInstance,
	dispatch: TDispatch,
	enableNonmarketableExclusion?: boolean
) => {
	const matchRulesApi = `/pls/matchrules/sourceId/${selectedSource.sourceId}?includeArchived=false&includeInactive=false`
	await apiClient
		.get(matchRulesApi)
		.then((matchRulesResponse: AxiosResponse<Array<GetMatchRulesResponse>>) => {
			const matchRules: Array<MatchRuleInfo> = []
			for (const savedMatchRule of matchRulesResponse.data) {
				const tempSavedMatchRule = savedMatchRule
				if (!enableNonmarketableExclusion) {
					tempSavedMatchRule.exclusionCriterion?.push('NonMarketable')
				}
				matchRules.push({
					matchRule: tempSavedMatchRule,
					advancedSettingsCollapsed: false,
					matchQualityType: savedMatchRule.acceptCriterion.MatchGradePatterns
						? GetMatchQualityMode(savedMatchRule.acceptCriterion.MatchGradePatterns)
						: MatchType.EASY,
					useEasyMatchString: !!(
						savedMatchRule.acceptCriterion.MatchGradePatterns &&
						savedMatchRule.acceptCriterion.MatchGradePatterns.length > 0
					)
				})
			}

			let matchingApproach = undefined
			if (matchRules.length > 0 && matchRules[0].matchRule.matchStyle === MatchStyle.MATCH_RECOMMENDATION) {
				matchingApproach = MatchingApproach.START_SCRATCH_MR
			}

			dispatch(
				updateCurrentProjectAction({
					matchRules: matchRules,
					matchRulesValidated: true,
					initialMatchRules: matchRules,
					matchingApproach
				})
			)
		})
		.catch((error) => {
			console.error(error)
		})
}
