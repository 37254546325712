import { AxiosInstance } from 'axios'
import {
	flattenElements,
	getCurrentEntitlement,
	getNotEntitledElements,
	mappingElementsToStructureForBlock
} from '../../helpers'
import { PurposeOfUse } from '../../types'
import { getDataBlocksElements } from './getDataBlocksElements'
import { getDataBlocksEntitlements } from './getDataBlocksEntitlements'
import { RequiredElementsResponse } from './getRequiredElementsForFamilyTree'

export const getRequiredElementsForConnectManage = async (
	apiClient: AxiosInstance,
	purposeOfUse: PurposeOfUse,
	requiredElements: string[]
): Promise<RequiredElementsResponse> => {
	const dataBlockElements = await getDataBlocksElements(apiClient)
	const dataBlockEntitlements = await getDataBlocksEntitlements(apiClient, undefined, undefined, true)
	const elementDetailList = getCurrentEntitlement(purposeOfUse, dataBlockEntitlements)
	const dataBlocks = mappingElementsToStructureForBlock(elementDetailList, requiredElements)
	const entitled = flattenElements(dataBlocks)
	const notEntitledElements = getNotEntitledElements(dataBlocks, requiredElements)
	const notEntitled = flattenElements(mappingElementsToStructureForBlock(dataBlockElements, notEntitledElements))

	return { entitled, notEntitled }
}
