/**
 * @class ContextMenu
 */

import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import Icon from '../icon/icon'
import styles from './context-menu.module.scss'

export interface ContextMenuOption {
	label: string
	action: string
	disabled?: boolean
	textTooltip?: string
}

interface DotsMenuProps {
	id?: string
	options: ContextMenuOption[]
	onMenuClicked: (option: string, indx: number) => void
	alignment?: 'right' | 'left'
	testId: string
	withIndicator?: string
}

export const ContextMenu: FC<PropsWithChildren<DotsMenuProps>> = ({
	children,
	id = undefined,
	options,
	onMenuClicked,
	alignment = 'right',
	testId,
	withIndicator = ''
}: PropsWithChildren<DotsMenuProps>) => {
	const [showMenu, setShowMenu] = useState(false)
	const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
	const buttonRef = useRef<HTMLSpanElement>(null)
	const menuRef = useRef<HTMLDivElement>(null)
	const [myId] = useState(id || `context-menu-${Math.random().toString(36).substr(2, 9)}`)
	const [tooltip, setTooltip] = useState<{ visible: boolean; text: string; top: number; left: number }>({
		visible: false,
		text: '',
		top: 0,
		left: 0
	})
	const toggleMenu = () => {
		setShowMenu((prev) => !prev)
	}

	const closeMenu = () => {
		setShowMenu(false)
	}

	const handleOutsideClick = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			closeMenu()
		}
	}

	const repositionMenu = () => {
		if (buttonRef.current) {
			const buttonRect = buttonRef.current.getBoundingClientRect()
			const newLeft = alignment === 'right' ? buttonRect.left : buttonRect.right - 200 // Adjust width as needed
			setMenuPosition({
				top: buttonRect.bottom + window.scrollY - 24,
				left: newLeft + window.scrollX
			})
		}
	}

	useEffect(() => {
		if (showMenu) {
			repositionMenu()
			document.addEventListener('mousedown', handleOutsideClick)
		} else {
			document.removeEventListener('mousedown', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showMenu])

	const handleMouseEnter = (e: MouseEvent<HTMLLIElement, MouseEvent>, text: string) => {
		if (text != '') {
			setTooltip({
				visible: true,
				text,
				top: e.target.offsetTop,
				left: alignment === 'right' ? e.target.offsetWidth - 45 : -200
			})
		}
	}

	const handleMouseLeave = () => {
		setTooltip({ visible: false, text: '', top: 0, left: 0 })
	}

	return (
		<span className={styles.context} ref={buttonRef} onClick={toggleMenu} id={myId} data-testid={testId}>
			{children ? (
				children
			) : (
				<Icon testId={`context-icon-dots-${testId}`} type="three-dots" color={'ColorGrayDark'} />
			)}

			{showMenu &&
				createPortal(
					<div
						ref={menuRef}
						className={cx(
							styles.context,
							styles.menu,
							styles[alignment],
							showMenu ? styles.show : styles.hide
						)}
						style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px`, position: 'absolute' }}
						id={'context-menu-options-' + testId}
					>
						<ul className={styles.optionList} data-testid={`ul-${testId}`}>
							{options.map((option, index) => (
								<li
									key={index}
									className={cx(
										styles.option,
										{
											[styles.disabledState]: option.disabled
										},
										{ [styles.tooltipEvent]: option.textTooltip !== '' },
										withIndicator !== '' && withIndicator === option.action ? styles.indicator : ''
									)}
									onClick={(e) => {
										e.stopPropagation()
										if (!option.disabled) {
											onMenuClicked(option.action, index)
											closeMenu()
										}
									}}
									onMouseEnter={(e) => handleMouseEnter(e, option.textTooltip || '')}
									onMouseLeave={handleMouseLeave}
									data-testid={`${testId}-option-${option.action}`}
								>
									<>
										{option.label}
										{withIndicator === option.action && (
											<Icon type={'simple-check'} color={ColorBlueBrand} size={'mini'} />
										)}
									</>
								</li>
							))}
						</ul>
						{tooltip.visible && (
							<div
								className={cx(styles.tooltipContent)}
								style={{
									position: 'absolute',
									top: `${tooltip.top}px`,
									left: `${tooltip.left}px`,
									zIndex: '2000'
								}}
							>
								<div
									className={styles.arrowTooltip}
									style={{ left: `${alignment === 'right' ? -3 : 196}px` }}
								></div>
								<div>{tooltip.text}</div>
							</div>
						)}
					</div>,
					document.body
				)}
		</span>
	)
}

export default ContextMenu
