import { DNBSelect, DNBSelectOption } from '@dnb-uux-design-system/react'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import styles from './pagination.module.scss'

interface Props {
	options: {
		value: number
		label: string
	}[]
	totalItems: number
	firstListItem: number
	lastListItem: number
	onNextPage(): void
	onPrevPage(): void
	onChangeItemsPerPage(items: number): void
}

export const Pagination = ({
	options,
	totalItems,
	firstListItem,
	lastListItem,
	onNextPage,
	onPrevPage,
	onChangeItemsPerPage
}: Props) => {
	const { t } = useTranslation()
	const [itemsPerPage, setItemsPerPage] = useState(options[0].value)
	const [currentPage, setCurrentPage] = useState(1)

	const handleItemsPerPageChange = (value: number) => {
		setItemsPerPage(value)
		setCurrentPage(1)
		onChangeItemsPerPage(value)
	}

	const handlePrevPage = () => {
		setCurrentPage(currentPage - 1)
		onPrevPage()
	}

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1)
		onNextPage()
	}

	return (
		<div className={styles.paginationContainer}>
			<div className={styles.itemsPerPage}>
				<label>{t('connection.source.items.per.page')}</label>
				<DNBSelect
					id={`itemsPerPage`}
					size="compact"
					value={itemsPerPage}
					onChange={(_event: any, selection: number) => handleItemsPerPageChange(selection)}
				>
					{options.map((option) => (
						<DNBSelectOption key={option.value} value={option.value}>
							{option.label}
						</DNBSelectOption>
					))}
				</DNBSelect>
				<label>{`${firstListItem} - ${lastListItem} ${t('connection.source.of')} ${totalItems} items`}</label>
				<div className={styles.paginationControls}>
					<div className={styles.button}>
						<Button onClick={handlePrevPage} disabled={firstListItem <= 1}>
							<Icon testId="chevron-left-source" type="chevron-left" size="mini" />
						</Button>
					</div>
					<div className={styles.button}>
						<Button onClick={handleNextPage} disabled={lastListItem >= totalItems}>
							<Icon testId="chevron-right-source" type="chevron-right" size="mini" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
