import { useTranslation } from 'react-i18next'
import style from './licenses-table.module.scss'
export const NotesToTable = () => {
	const { t } = useTranslation()
	return (
		<div className={style.listNotes}>
			<span>{'*' + t('page.settings.general.note.users')}</span>
			<span>{'**' + t('page.settings.general.note.records')}</span>
		</div>
	)
}
