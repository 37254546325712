import { DNBButton } from '@dnb-uux-design-system/react'
import { FC, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './record-limit-exceeded.module.scss'

interface RecordLimitExceededProps {
	maxFileRows: number
	totalRows: number
	onCancel: () => void
	onContinue: () => void
	testId: string
	isContinueFirst?: boolean
	description?: string | ReactElement
	continueButtonText?: string
	cancelButtonText?: string
}
export const RecordLimitExceeded: FC<RecordLimitExceededProps> = ({
	maxFileRows,
	totalRows,
	onContinue,
	onCancel,
	testId,
	isContinueFirst: isConfirmFirst = true,
	description,
	continueButtonText,
	cancelButtonText
}: RecordLimitExceededProps) => {
	const { t } = useTranslation()

	const continueButton = (
		<DNBButton
			size="default"
			variant={isConfirmFirst ? 'secondary' : 'primary'}
			onClick={onContinue}
			data-testid={`${testId}-record-limit-modal-continue`}
		>
			{continueButtonText ?? t('upload.chooser.modal.recordLimit.button.continue')}
		</DNBButton>
	)

	const cancelButton = (
		<DNBButton
			size="default"
			variant={isConfirmFirst ? 'primary' : 'secondary'}
			onClick={onCancel}
			data-testid={`${testId}-record-limit-modal-cancel`}
		>
			{cancelButtonText ?? t('upload.chooser.modal.recordLimit.button.cancel')}
		</DNBButton>
	)

	return (
		<div className={styles.recordLimitModalContent}>
			<div className={styles.textWrapper}>
				<p className={styles.title}>{t('upload.chooser.modal.recordLimit.title')}</p>

				<p className={styles.description}>
					{description ?? (
						<Trans
							i18nKey="upload.chooser.modal.recordLimit.description"
							tOptions={{
								recordLimit: maxFileRows.toLocaleString(),
								totalRows: totalRows.toLocaleString()
							}}
						/>
					)}
				</p>
			</div>
			<div className={styles.confirmationButtonsContainer}>
				{isConfirmFirst ? (
					<>
						{continueButton}
						{cancelButton}
					</>
				) : (
					<>
						{cancelButton}
						{continueButton}
					</>
				)}
			</div>
		</div>
	)
}
