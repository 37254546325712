import { ColorGreenBottleLight, ColorSlateLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton, DNBInputLabel } from '@dnb-uux-design-system/react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dbconnect from '../../assets/images/dnb-amp-logo.svg'
import { Divider, Icon, Input, Modal } from '../../local-core-ui'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import styles from './connect-manage-modal.module.scss'

export interface connectData {
	apiUrl: string
	apiKey: string
}
interface ConnectManageModalProps {
	open: boolean
	onClose: () => void
	verifyData: (systemType: string, data: connectData) => void
	dataVerified: boolean | undefined
	isDataValid: boolean
	changeData: () => void
	onSave(connectData?: connectData): void
	testId: string
}
export const ConnectManageModal = ({
	open,
	onClose,
	verifyData,
	dataVerified,
	changeData,
	isDataValid,
	onSave,
	testId
}: ConnectManageModalProps): ReactElement => {
	const { t } = useTranslation()
	const [disableVerifier, setDisableVerifier] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<connectData>({ apiUrl: '', apiKey: '' })
	const [isApiKeyMasked, setIsApiKeyMasked] = useState<boolean>(false)

	const isURL = (str: string) => {
		const urlRegex =
			'^(https?://)((?!-)[a-zA-Z0-9]{1,63}(?<!-).)+[a-zA-Z]{2,63}.com(.[a-zA-Z]{2,63})?(/[a-zA-Z0-9-/]*)?$'
		const url = new RegExp(urlRegex, 'i')
		return str.length < 2083 && url.test(str)
	}

	const maskApiKey = (apiKey: string) => {
		return apiKey.length > 4 ? `${'*'.repeat(apiKey.length - 4)}${apiKey.substring(apiKey.length - 4)}` : apiKey
	}

	useEffect(() => {
		if (data.apiUrl !== '' && isURL(data.apiUrl) && data.apiKey !== '') {
			setDisableVerifier(false)
		} else {
			setDisableVerifier(true)
		}
		changeData()
	}, [data])

	useEffect(() => {
		setIsLoading(false)

		if (data.apiKey !== '') setIsApiKeyMasked(isDataValid)
	}, [dataVerified, isDataValid])

	return (
		<div className={styles.connectManageModal}>
			<Modal
				open={open}
				testId={`${testId}-connect-manage-modal`}
				showButtonClose={true}
				isContainer={true}
				fixWidth={false}
				onClose={() => {
					setData({ apiUrl: '', apiKey: '' })
					onClose()
					setIsApiKeyMasked(false)
				}}
			>
				<div className={styles.containerModal}>
					<div className={styles.header}>
						<div className={styles.imgContainer}>
							<img src={dbconnect} alt={''} />
						</div>
						<div className={styles.title}>{t('title.modal.new.connect.manage.connection')}</div>
					</div>
					<div className={styles.body}>
						{dataVerified && !isDataValid && !isLoading && (
							<Snackbar title={t('snackbar.modal.validation.failed')} type={SnackType.error} />
						)}
						<div>
							<DNBInputLabel>
								{t('title.input.url.modal.new.connection')}
								<span className={styles.symbolRequired}>{'*'}</span>
							</DNBInputLabel>
							<Input
								id={'input-url-connect-manage-modal'}
								hint={t('hint.input.url.modal.new.connection')}
								onChangeFunction={(value: string) => {
									setData({ ...data, apiUrl: value, apiKey: '' })
									setIsApiKeyMasked(false)
								}}
								value={data.apiUrl}
								label={''}
								testId={'input-url-connect-manage-modal'}
								size={'fluid'}
							/>
							<DNBInputLabel>
								{t('title.input.key.modal.new.connection')}
								<span className={styles.symbolRequired}>{'*'}</span>
							</DNBInputLabel>
							<Input
								id={'input-url-connect-manage-modal'}
								hint={t('hint.input.key.modal.new.connection')}
								onChangeFunction={(value: string) => {
									if (value === '') {
										setIsApiKeyMasked(false)
									}
									setData({ ...data, apiKey: value })
								}}
								value={isApiKeyMasked ? maskApiKey(data.apiKey) : data.apiKey}
								label={''}
								testId={'input-url-connect-manage-modal'}
								size={'fluid'}
								disabled={isApiKeyMasked}
							/>
						</div>
						{dataVerified && isDataValid ? (
							<div className={styles.containerMsgSucces}>
								<Icon type={'accepted'} color={ColorGreenBottleLight} size={'mini'} />
								<span className={styles.textSuccess}>{t('text.modal.validation.success')}</span>
							</div>
						) : (
							<DNBButton
								disabled={disableVerifier}
								isLoading={isLoading}
								variant={'secondary'}
								onClick={() => {
									setIsLoading(true)
									verifyData('connectManage', data)
									setIsApiKeyMasked(true)
								}}
							>
								{t('button.text.modal.new.connection.validator')}
							</DNBButton>
						)}
					</div>
					<div className={styles.bottomSection}>
						<Divider color={ColorSlateLight} />
						<div className={styles.containerButtons}>
							<DNBButton
								onClick={() => {
									setData({ apiUrl: '', apiKey: '' })
									onClose()
									setIsApiKeyMasked(false)
								}}
								variant={'secondary'}
							>
								{t('button.text.modal.new.connection.cancel')}
							</DNBButton>
							<DNBButton
								onClick={() => {
									onSave(data)
									setData({ apiUrl: '', apiKey: '' })
									setIsApiKeyMasked(false)
								}}
								disabled={isLoading || !dataVerified || !isDataValid}
							>
								{t('button.text.modal.new.connection.save')}
							</DNBButton>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}
