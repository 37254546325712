import { DNBDrawer, DNBDrawerBaseItem, DNBSideNavigationDrawerItem } from '@dnb-uux-design-system/react'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { ComponentType, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { DataRetentionSettings } from '../../components/data-retention-settings/data-retention-settings'
import { LicenseDetailsSettings } from '../../components/license-details-settings/license-details-settings'
import { useFeatures } from '../../hooks/useEntitlements'
import { Icon } from '../../local-core-ui'
import styles from './dashboard-settings.module.scss'

interface SettingDefinition extends DNBDrawerBaseItem {
	path: string
	component: ComponentType<unknown>
}

export const DashboardSettings = (): ReactElement => {
	const { t } = useTranslation()
	const history = useHistory()
	const location = useLocation()

	const [selectedItemValue, setSelectedItemValue] = useState('general')
	const [isOpen, setIsOpen] = useState(true)
	const isEnableDataRetentionPolicy = useFeatures(['EnableDataRetentionPolicy'])
	const SETTINGS_PATH = '/settings'

	const generalDefinition: SettingDefinition = {
		component: LicenseDetailsSettings,
		icon: <Icon type="fingerprint" />,
		id: 'general',
		label: t('dashboard.settings.general'),
		path: '/general',
		value: 'general'
	}

	const dataRetentionDefinition: SettingDefinition = {
		component: DataRetentionSettings,
		icon: <ApartmentIcon />,
		id: 'data-retention',
		label: t('dashboard.settings.dataRetention'),
		path: '/data-retention',
		value: 'data-retention'
	}

	const settingsList: SettingDefinition[] = [generalDefinition]

	if (isEnableDataRetentionPolicy) {
		settingsList.push(dataRetentionDefinition)
	}

	const handleClickItem = ({ path }: SettingDefinition) => {
		const newPath = `${SETTINGS_PATH}${path}`
		history.push(newPath)
	}

	useEffect(() => {
		const currentPath = location.pathname
		const currentSettingPath = currentPath.replace(`${SETTINGS_PATH}`, '')
		const currentSetting = settingsList.find((setting) => setting.path === currentSettingPath)

		if (currentPath === SETTINGS_PATH) {
			history.push(`${SETTINGS_PATH}/general`)
		}

		if (currentSetting) {
			setSelectedItemValue(currentSetting.value)
		}
	}, [location, history, settingsList])

	return (
		<div className={styles.container}>
			<div className={`${styles.drawerContainer} ${!isOpen ? styles.drawerClosed : ''}`}>
				<DNBDrawer
					border
					data-testid={'dashboard-settings-drawer'}
					onClose={() => setIsOpen(false)}
					onToggle={() => setIsOpen(!isOpen)}
					open={isOpen}
					relative
					variant="persistent"
				>
					{settingsList.map((settings) => (
						<DNBSideNavigationDrawerItem
							data-testid={`dashboard-settings-drawer-item-${settings.value}`}
							item={settings}
							key={settings.id}
							onClick={() => handleClickItem(settings)}
							selectedOption={selectedItemValue}
						/>
					))}
				</DNBDrawer>
			</div>
			<div className={styles.settingPage}>
				<Switch>
					{settingsList.map(({ component, path, value }) => (
						<Route key={value} path={`${SETTINGS_PATH}${path}`} component={component} />
					))}
				</Switch>
			</div>
		</div>
	)
}
