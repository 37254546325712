import { AxiosInstance, AxiosResponse } from 'axios'
import { ConnectionStatus } from './getC4SActiveConnection'

export enum System {
	Salesforce = 'Salesforce',
	Snowflake = 'Snowflake',
	ConnectManage = 'ConnectManage'
}

export interface Connection {
	name: string
	system: System
	created: number
	updated: number
	user: string
	status: ConnectionStatus
	displayName: string
}

export const getConnections = async (apiClient: AxiosInstance, systemType?: System): Promise<Connection[]> => {
	let allConnectionsReceived = false
	const ConnectionsList: Connection[] = []
	let pageIdx = 0
	while (!allConnectionsReceived) {
		const url = systemType
			? `/pls/integration/connection?pageSize=12&pageIndex=${pageIdx}&systemType=${systemType}`
			: `/pls/integration/connection?pageSize=100&pageIndex=${pageIdx}`

		await apiClient
			.get(url)
			.then((response: AxiosResponse<Connection[]>) => {
				const connections: Connection[] = response.data
				if (connections.length === 0) {
					allConnectionsReceived = true
				} else {
					ConnectionsList.push(...connections)
					if (connections.length < 100) {
						allConnectionsReceived = true
					} else {
						pageIdx++
					}
				}
			})
			.catch(() => {
				allConnectionsReceived = true
			})
	}

	return ConnectionsList
}
