import { QueryClient } from 'react-query'
import flattenArrayConfiguration from '../../../components/data-block-searcher/flattenArray-configuration.json'
import RMRequiredElementList from '../../../project/steps/enriching/select-your-data/recommendation-default-elements.json'
import c4sRequiredElementList from '../../../project/steps/enriching/select-your-data/required-c4s-elements.json'
import requiredElementList from '../../../project/steps/enriching/select-your-data/required-elements.json'
import { Element, MatchingApproach } from '../../../types'
import { getDefaultElementList } from '../../../utils'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'
import { EnrichingBlock } from '../types'
import { getDefaultElements } from './getDefaultElements'
import { validateDataBlocksForOrderReason } from './validateDatablocksForOrderReason'

export const createOrUpdateLayout =
	(
		queryClient: QueryClient,
		isActiveTradeUp: boolean,
		isEnabledInternationalContacts: boolean
	): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		dispatch(updateLoadingNextStep(true))
		const currentProject = getState().projectWizard.currentProject
		const sourceId = currentProject.source.id
		const layoutId = currentProject.layoutId
		const selectSession = getState().session.user?.EmailAddress
		const apiClient = getApiClient4Thunks(dispatch)
		const url = `/pls/enrichment-layouts/`
		let elements: string[] = []
		const hasCRMSource = currentProject.thirdPartyIntegration !== undefined
		const isRecommendationMatch = currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
		//DCP-5594,5595,5598 concating default elements with contact default elements
		const defaultElementList = getDefaultElementList(
			currentProject.source?.entityType,
			currentProject.source?.enable_email_verification,
			apiClient,
			isRecommendationMatch
		)
		//TODO Delete when hardcoded items are deleted
		const defaultElements: Array<EnrichingBlock> = hasCRMSource ? [] : [...defaultElementList]
		currentProject.enrichingLayout.forEach((block) => {
			const valuesSelectedOfBlock = block.elements.map((element: Element) => {
				return element.elementId
			})
			elements = elements.concat(valuesSelectedOfBlock.filter((item) => elements.indexOf(item) === -1))
		})

		//TODO Delete 'flattenArrayConfiguration.contactComplianceId.isRequiredByDefault' when TECHDEBT-2314 has been completed
		elements = elements.filter(
			(elementId) =>
				!getDefaultElements(defaultElements).includes(elementId) ||
				(flattenArrayConfiguration.contactComplianceId.isRequiredByDefault.some((requiredElement) =>
					elementId.startsWith(requiredElement)
				) &&
					isEnabledInternationalContacts)
		)
		const missingRequiredElementList = requiredElementList.filter((elementId) => !elements.includes(elementId))
		elements.push(...missingRequiredElementList)
		if (hasCRMSource) {
			const c4sMissingRequiredElementList = c4sRequiredElementList.filter(
				(elementId) => !elements.includes(elementId)
			)
			elements.push(...c4sMissingRequiredElementList)
		}
		// DCP-196 - For Match Recommendation add 4 output elements to CER data block
		if (isRecommendationMatch) {
			RMRequiredElementList.forEach((ele) => {
				elements.push(ele.elementId)
			})
		}
		const data = {
			domain: currentProject.purposeOfUse.domain,
			recordType: currentProject.purposeOfUse.recordType,
			elements: [...new Set(elements)],
			sourceId: sourceId,
			createdBy: selectSession,
			generateJSON: currentProject.generateJson,
			tradeUp: isActiveTradeUp && currentProject.tradeUp ? currentProject.entityTradeUp : null,
			includeBranches: currentProject.includeBranches ?? null,
			orderReason: validateDataBlocksForOrderReason(currentProject.enrichingLayout)
				? currentProject.orderReason
				: null
		}

		let apiCall
		if (layoutId) {
			apiCall = apiClient.put(url, { ...data, layoutId: layoutId })
		} else {
			apiCall = apiClient.post(url, data)
		}
		return apiCall
			.then((response) => {
				if (response.status === 200 && response.data.Success) {
					queryClient.invalidateQueries(['getTemplateEnrichmentRules', sourceId])
					queryClient.invalidateQueries(['getCustomObjectUsage', sourceId])
					dispatch(updateCurrentProjectAction({ layoutId: response.data.Result }))
					dispatch(updateLoadingNextStep(false))
					return new Promise<void>((resolve) => resolve())
				} else {
					dispatch(updateLoadingNextStep(false))
					return new Promise<void>((resolve, reject) => reject(response.data.Errors))
				}
			})
			.catch((e) => {
				dispatch(updateLoadingNextStep(false))
				throw e
			})
	}
