import { DNBTypography } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import { LicenseType } from '../../store/session/types/LicenseType'
import { CurrentLicenseDetail } from './current-license-detail/current-license-detail'
import styles from './license-details-settings.module.scss'
import { getHeadersTable, getLicensesToShow } from './licenses-table/getLicensesToShow'
import { LicensesTable } from './licenses-table/licenses-table'
import { NotesToTable } from './licenses-table/notesToTable'
export const LicenseDetailsSettings = (): ReactElement => {
	const { t } = useTranslation()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const currentLicense = session.currentLicense

	const benefitsCurrentLicense = [
		{
			name: t('page.settings.general.current.benefit.type.license'),
			description: t('page.settings.general.current.description.type.' + currentLicense.licenseType)
		},
		{
			name: t('page.settings.general.current.benefit.records.upload'),
			description: Intl.NumberFormat().format(currentLicense.recordLimit)
		},
		{
			name: t('page.settings.general.current.benefit.purchased.seats'),
			description: Intl.NumberFormat().format(currentLicense.usersLimit)
		},
		{ name: t('page.settings.general.current.benefit.current.seats'), description: Intl.NumberFormat().format(1) }
	]

	return (
		<div className={styles.licenseDetailsPageContainer}>
			<DNBTypography variant="h4">{t('page.settings.general.title')}</DNBTypography>
			<CurrentLicenseDetail
				benefitsCurrentLicense={benefitsCurrentLicense}
				currentLicense={currentLicense.licenseType}
			/>
			{currentLicense.licenseType !== LicenseType.LEGACY && (
				<div className={styles.availableLicensesTableContainer}>
					<DNBTypography variant="h5">{t('page.settings.general.license.levels')}</DNBTypography>
					<Divider></Divider>
					<LicensesTable
						columns={getHeadersTable(currentLicense.licenseType, t)}
						groupRows={getLicensesToShow(currentLicense.licenseType)}
					/>
					<NotesToTable />
				</div>
			)}
		</div>
	)
}
